import _ from 'lodash'
import ContentfulClient from './client'

const contentfulClient = () => {
  return ContentfulClient.getClient()
}

const transformEntry = (entry) => {
  const id = _.get(entry, 'sys.id')
  return {
    ...entry,
    id
  }
}

export const fetchResource = async ({ id }) => {
  if (!id) throw new Error('id required to fetch resource')
  return contentfulClient().getEntry(id)
    .then(transformEntry)
}

export const fetchResources = ({
  resourceType,
  where,
  select,
  order,
  skip,
  limit,
  include
}) => {
  const params = {
    content_type: resourceType,
    skip,
    limit,
    include,
    order,
    select,
    ...where
  }
  return contentfulClient().getEntries(params)
    .then((result) => {
      const { items, includes, sys, ...rest } = result || {}
      return {
        ...rest,
        resources: _.map(items, transformEntry)
      }
    })
}
