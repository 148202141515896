import renderRichText from './renderRichText'
import { fetchResource, fetchResources } from './resources'
import contentfulClient from './client'

const contentfulService = {
  renderRichText,
  fetchResource,
  fetchResources,
  contentfulClient
}

export {
  renderRichText,
  fetchResource,
  fetchResources,
  contentfulClient
}

export default contentfulService
